@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  font-family: avenir-regular, helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
}
html h1 {
  font-size: 2rem;
}